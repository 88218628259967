import { action, thunk, computed } from "easy-peasy";
import { assign, pick } from "lodash";
import { easyStateSetters } from "lib/easyState";
import { paginationStore, enhancePaginationProps } from "lib/paginationStore";
import {
  fetchStopLocations,
  fetchStopLocation,
  submitStopLocation,
  apiArchiveStopLocation,
  updateStopLocation
} from "services/apiStopLocations";
import batchActionsStore from "components/admin_v2/common/stores/batchActionsStore";
import { setFlashMessage } from "services";

const STOP_FORM = {
  schoolId: null,
  name: null,
  addressAttributes: {
    id: null,
    address: null,
    lat: null,
    lng: null
  }
};

export const defaultState = {
  schoolId: null,
  status: null,
  // status
  addStopLocationDialogOpen: false,
  loading: false,
  submitDisabled: false,
  refresh: false,
  // filters
  query: null,
  // data
  stopLocations: [],
  stopLocation: null,
  stopLocationForm: { ...STOP_FORM },
  errors: {}
};

export const stopLocationsStore = (initialData) => ({
  ...easyStateSetters(defaultState, initialData),
  ...paginationStore(initialData),
  ...batchActionsStore(initialData),

  editDisabled: computed((state) => state.stopLocation?.is_school),

  setFromRouter: action((state, props) => {
    const defaultProps = pick(defaultState, ["schoolId", "status"]);
    assign(state, defaultProps, enhancePaginationProps(props));
  }),

  fetchStopLocations: thunk((actions, params, h) => {
    const stateParams = pick(h.getState(), ["query", "page", "perPage", "status"]);

    actions.setLoading(true);

    return fetchStopLocations({ ...params, ...stateParams })
      .then((r) => actions.setData(r))
      .catch((err) => setFlashMessage(err.message))
      .finally(() => actions.setLoading(false));
  }),

  setData: action((state, data) => {
    state.stopLocations = data.stop_locations;
    state.collection = data.stop_locations;
    state.total = parseInt(data.total);
  }),

  addStopLocation: action((state, stopLocationForm) => {
    let stopLocationsList = state.stopLocations;
    stopLocationsList.unshift(stopLocationForm);
    state.stopLocations = stopLocationsList;
  }),

  removeStopLocation: action((state, archivedId) => {
    const newStopsList = state.stopLocations.filter((sl) => sl.id !== archivedId);
    state.stopLocations = newStopsList;
  }),

  fetchStopLocation: thunk((actions, id, _h) => {
    actions.setLoading(true);

    fetchStopLocation(id)
      .then((resp) => {
        const attrs = {
          schoolId: resp.stop_location.school_id,
          name: resp.stop_location.name,
          addressAttributes: {
            id: resp.stop_location.address_id,
            address: resp.stop_location.address,
            lng: resp.stop_location.lng,
            lat: resp.stop_location.lat
          }
        };
        actions.setStopLocation(resp.stop_location);
        actions.setStopLocationForm(attrs);
      })
      .finally(() => actions.setLoading(false));
  }),

  archiveStopLocation: thunk((actions, id, _h) => {
    actions.setLoading(true);

    apiArchiveStopLocation(id)
      .then((resp) => {
        setFlashMessage(resp.message);
        actions.removeStopLocation(id);
      })
      .catch((err) => {
        setFlashMessage(err.message);
      })
      .finally(() => actions.setLoading(false));
  }),

  updateStopLocation: thunk((actions, _payload, h) => {
    actions.setSubmitDisabled(true);
    actions.setErrors({});
    const { stopLocationForm, stopLocation } = h.getState();
    return updateStopLocation(stopLocation.id, { stopLocation: stopLocationForm })
      .then((resp) => {
        setFlashMessage(resp.message);
      })
      .catch((err) => {
        const errors = err.response?.data?.errors;
        actions.setErrors(errors);
        throw err;
      })
      .finally(() => actions.setSubmitDisabled(false));
  }),

  submit: thunk((actions, _payload, h) => {
    actions.setSubmitDisabled(true);
    actions.setErrors({});
    const { stopLocationForm } = h.getState();

    submitStopLocation({ stopLocation: stopLocationForm })
      .then((resp) => {
        setFlashMessage(resp.message);
        actions.addStopLocation(resp.stop_location);
        actions.setAddStopLocationDialogOpen(false);
        actions.setStopLocationForm({ ...STOP_FORM });
      })
      .catch((err) => {
        const errors = err.response?.data?.errors;
        actions.setErrors(errors);
      })
      .finally(() => actions.setSubmitDisabled(false));
  })
});
