import { map } from "lodash";
import I18n from "utils/i18n.js";

export const ACCOMODATION_TYPES = ["curb_to_curb", "solo", "aide", "wheelchair", "small_vehicle"];

export const INCIDENT_REPORT_URL =
  "https://docs.google.com/forms/d/e/1FAIpQLSekV9Pg0WB96xj6bC6G1fs2w93DBAA9iK3tpIgLtm_F8vTQrw/viewform";

export const GRADES = [
  "PK",
  "K",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "A"
];

export const STAFF_BOOLEAN_FIELDS = ["systemUser", "communicationAccess", "readOnly"];

export const WEEKDAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

export const PROVIDERS_WITH_DEVICES = [
  "osg",
  "zonar",
  "geotab",
  "samsara",
  "trax",
  "azuga",
  "quartix",
  "adroit",
  "zubie",
  "motive",
  "synovia",
  "linxup"
];

export const NON_RIDING_TYPES = ["PPU", "walk", "distance", "carpool", "transit"];

export const FEED_TYPES = {
  School: "school",
  District: "district",
  CommunicationGroup: "group",
  CustomCommunicationGroup: "custom_group",
  Student: "direct",
  User: "direct",
  Staff: "direct"
};

export const TRIP_TYPE_FILTER_OPTIONS = ["all", "regular_only", "all_other"];

export const DIRECTION_OPTIONS = map(["to_school", "from_school", "custom"], (val) => ({
  id: val,
  name: I18n.t(`ui.curbside.directions.${val}`)
}));

export const VEHICLE_TYPES = [
  { id: "bus", name: "Bus" },
  { id: "cab", name: "Cab" },
  { id: "van", name: "Van" }
];

export const ROUTE_SHEETS_ADD_DAYS = 6;

export const TRIP_DIRECTIONS = {
  to_school: "inbound",
  to_custom: "inbound",
  from_school: "outbound",
  from_custom: "outbound"
};

export const LINE_TYPES = ["mobile", "landline"];
export const LANGUAGES = [
  "english",
  "spanish",
  "somali",
  "french",
  "russian",
  "pashto",
  "hmong",
  "karen"
];

export const PERIOD_DATE_OPTIONS = [
  {
    id: true,
    label: I18n.t(`data_management.assigned_vehicles.form.permanent.label`)
  },
  {
    id: false,
    label: I18n.t(`data_management.assigned_vehicles.form.one_day.label`)
  }
];
